@import '../../../shared/styles/variable';

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  // padding: 20px 20px 0;
  min-height: auto;

  & > div {
    height: 100%; 
    & > .ant-card {
      height: 100%; 
    }
  }

  &:has(.login-img) {
    padding: 0;
  }
  .card{
    border-radius: 0px !important;
  };

}

@primary-color: #0A8FDC;