.modal {
    position: relative;
    .modal-step {
        margin: 20px 0 30px ;
        width: 100%;
        .ant-steps-icon {
            color: #fff;
        }
        .ant-steps-item-finish .ant-steps-item-icon {
            background: #0A8FDC;
        } 
        .ant-steps-item-wait .ant-steps-item-icon {
            background: rgba(0, 0, 0, 0.38);;
        } 
    }

    .modal-content {
        & .title {
            font-size: 20px;
            color: #2196F3
        }
        & .description {
            font-size: 14px;
        }
        & .description-map-header {
            font-size: 14px;
            margin-top:20px
        }

        & .value-map-header {
            margin: 12px 0 46px;
            display: flex;
            column-gap: 6px;
            row-gap: 6px;
            flex-wrap: wrap;
            .value-item {
                padding: 8px 10px;
                border-radius: 50px;
                border:1px solid #DFDFDF;
              
            }

            .value-item-chosen {
                color:#0288D1;
                background: #F8FBFE;
                border : 1px solid #C2DAF8;
            }
        }

        & .group-btn {
            display: flex;
            justify-content: space-between;
        }

        & .group-set-btn {
            margin-top: 10px;
            column-gap: 10px;
            display: flex;
        }

        & .save-header-btn {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }

        & .ant-table-content {
            overflow: scroll;
        }

        & .gif-wrapper {
            flex:1;
            display: flex;
            justify-content: center;
            height: 20vh;
            .gif {
                width: 80%;
                background: #D9D9D9;
            }
        }

        &:has(.gif-wrapper) > div {
            display: flex;
            margin-bottom: 20px;

            & > div {
                flex:1
            }
        }

        & .ant-form-item {
            margin:8px 0;
        
        }

        &  .my-select-container .ant-select .ant-select-selector {
            border-radius:40px;
          }

        & .ant-form-item-label > label {
            color:rgba(0, 0, 0, 0.60)
        }

        & .modal-form-docs {
            height: calc(80vh - 140px);
            overflow: auto;
            overflow-x: hidden;
            .co-form-select.select-box {
                margin-left: 0px !important;
            }
            .external-switch {
                margin-top:17px;

                .external-switch-title {
                    margin-bottom: 12px;
                    color:rgba(0, 0, 0, 0.60)
                }

                .external-switch-row {
                    flex-direction: column !important;
                    & > .ant-col {
                        display: flex;
                        max-width: 175px;
                        align-items: center;
                        justify-content: space-between;   
                        
                        & > label {
                            color:rgba(0, 0, 0, 0.60)
                        }
                    }
                }
            }

            .internal-input {
                .internal-input-row-container {
                    width: 100%;
                    padding: 16px;
                    background-color: #FAFAFA;
                    border-radius: 4px;
                    margin: 20px 0;

                    .internal-input-title {
                        color:rgba(0, 0, 0, 0.87);
                        font-weight: 700;
                        margin-bottom: 20px;
                    }

                }
            }   
        }

    & .download-report-co {
            height: 55vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 20px;

            .description {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 10px;
            
            }
    }
    }

    .modal-btn {
        position: absolute;
        right:35px !important;
        bottom: 24px;
        display: flex;
        column-gap: 4px;
    }

    .modal-upload-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       margin-top: 50px;
        .modal-container {
            width: 568px;
            height: 104px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .input {
                margin-top: 10px;
            }

            .modal-upload-btn {
                span {
                    color: #2196F3;
                    cursor: pointer;
                    &:hover {text-decoration: underline;}
                }
        }

        .description {
            color:  rgba(0, 0, 0, 0.60);
            margin-top: 6px;
        }
        }
    }

    .modal-upload-description {
        width: 590px;
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
        border-radius: 4px;
        .description-wrapper {
            display: flex;
            column-gap: 10px;
            .description  {
                .name {

                }
                .capacity {
                    color: rgba(0, 0, 0, 0.60);
                    font-weight: 400;
                }
            }
        }

        .delete {
            cursor: pointer;
            & .delete-icon:hover {
                color:#2196F3 !important
            }
        }

      
    }

    .select-header {
        display: flex;
        margin-bottom: 20px;
        .description {
            flex: 1
        }
       
    }

    .modal-form {
        margin:30px 0 0
    }
}
@primary-color: #0A8FDC;