@import "../../../../../shared/styles/variable";
@import "../../../../../shared/styles/mixin";

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.page-header-input-view {
  display: flex;

  #page-name {
    margin-right: 10px;
    max-width: 350px;
    width: 300px;
    @media screen and (min-width: @screen-sm) {
      max-width: 350px;
    }
  }
}

.page-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 10px;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: 10px;
  }
}

.page-default {
  color: white;
  background-color: #0a8fdc;
  border-radius: 4px;
  padding: 2px 8px;
}

.cell-item {
  cursor: pointer;
}

.table-posts-content {
  display: -webkit-box;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  visibility: visible;
}

.table-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(10, 1fr);
}

.choosing-table {
  grid-column: span 3;
}

.result-table {
  grid-column: span 7;
}

.ant-table-thead {
  height: 30px !important;
}

.table-row-selected {
  background-color: #0a8fdca3;
}

.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: white !important;
}

// .ant-table-body {
//   height: 75vh !important;
// }

@primary-color: #0A8FDC;