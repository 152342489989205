@import "../../../shared/styles/variable";
@import "../../../shared/styles/mixin";

.card-setting {
  border-radius: 0;
  height: 100%;

  .formSetting {
    .ant-form-item-row {
      justify-content: center;
      & > .ant-form-item-label-left {
        display: flex;
      }
    }
  }
  .app-card-setting .ant-card-body {
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
  }
  .heading {
    display: flex;
    justify-content: space-between;
  }

  .ant-form-item-control-input-content {
    .date-picker {
      width: 100%;
    }
  }
}

@primary-color: #0A8FDC;