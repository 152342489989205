.modal-step-bangke {
  margin: 0 0 20px;
  width: 50%;
  .ant-steps-icon {
    color: #fff;
  }
  // .ant-steps-item-finish .ant-steps-item-icon {
  //   background: #0a8fdc;
  // }
  .ant-steps-item-wait .ant-steps-item-icon {
    background: rgba(0, 0, 0, 0.38);
  }
}

.wrapper-step1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 126px;
  height: 100%;
}

.image-step1 {
  position: relative;
  width: 100%;
  height: 418px;
  object-fit: cover;
}

// .table-step-two .ant-table-cell {
//   padding: 16px 16px !important;
// }
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.htBold {
  font-weight: bold;
}

.htItalic {
  font-style: italic;
}

.htEnd {
  text-align: right;
}

.htCellNoWrap {
  white-space: nowrap !important;
}

.error-wrapper {
  border: 1px solid red;
  padding: 20px;
  border-radius: 4px;
  background: #ffeeee;
}

.empty-text {
  color: red;
  font-size: 24px;
  font-family: "Courier New", Courier, monospace;
}
.error-text {
  color: red;
  font-size: large;
  font-family: "Courier New", Courier, monospace;
  padding-top: 16px;
}

.wrapper-table {
  margin-top: 10px;
}
@primary-color: #0A8FDC;