@import "../../../shared/styles/variable";
@import "../../../shared/styles/mixin";
.table-responsive .ant-table {
  min-height: 0.01%;
}
.ant-table-content {
  // overflow: unset !important;
  tr > th {
    position: sticky;
    top: 0;
    z-index: 1000;
    &:hover {
      background: @grey-100 !important;
    }
  }
}
.table-row-light {
  background-color: #ffffff;
  height: 30px;
}
.table-row-dark {
  background-color: #fbfbfb;
  height: 30px;
}

.table-responsive thead > tr > th,
.table-responsive tbody > tr > th,
.table-responsive tfoot > tr > th,
.table-responsive thead > tr > td,
// .table-responsive tbody > tr > td,
.table-responsive tfoot > tr > td {
  white-space: nowrap;
}

.table-responsive.empty table {
  width: auto;
  min-width: unset;
}

@media screen and (max-width: @screen-sm-max) {
  .table-responsive .ant-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: @border-style-base @border-width-base @border-color-base;
  }
}
.ant-table-cell {
  padding: 1px 8px !important;
}

.hoverColor {
  & tbody > tr {
    .transition(all 0.2s ease);
    .scale(1);

    &:hover {
      .translateY(-2px);
      box-shadow: 0 3px 10px 0 fade(@black, 20);
      background-color: fade(@primary-color, 10);
    }
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent;
  }
}
.ant-table-thead th {
  margin-bottom: 12px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.drag-handle {
  cursor: grab;
  margin-right: 8px;
  user-select: none;
  z-index: 1000;
  position: relative;
  width: fit-content;
}

.drag-handle:active {
  cursor: grabbing;
}

.ant-table-cell {
  padding-left: 16px;
}

.ant-table-cell-fix-sticky {
  z-index: 1002;
}
.ant-table-sticky-scroll-bar {
  display: none;
}

.ant-table-column-sorters {
  display: inline-flex;
  width: 80%;
  overflow: hidden;
}

@primary-color: #0A8FDC;