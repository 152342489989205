.app-view-detail {
    &-header {
        display: flex;
        justify-content: space-between;
    }
    .btn-back {
        & > span {
            margin-right: 10px;
        }
    }
}
@primary-color: #0A8FDC;